<template>
    <div>
        <v-container fluid>
            <v-row justify='space-between' class="pb-5">
                <v-col>
                    <h1 style="color: #3E4555;">STUDENT LIST</h1>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

export default {
    name: 'StudentList'
  }
</script>